/**
 * HOW TO USE
 * 
 * ("text to translate")
 * ("text to translate","lg")
 * ("text to translate",["repalce %s"])
 * ("text to translate",["repalce %s"],'lg')
 * 
 * $("div")._e("text to translate");
 * document.getElementById("#to_trans")._e("text to translate");
 * __("text to translate");
 * Translator.trans("text to translate");
 * Translator.getLang();
 * Translator.setLang("lg");
 * Translator.add("lg","text to translate","Translator Responce");
 * Translator.add("lg","text to translate",{
 *  0: "0 as param[0]"
 *  1: "1 as param[0]"
 *  '': "everyting else as param[0]"
 * });
 * 
 */

function TranslatorJS(){
    var lang = "en";
    if(document.querySelector("html").lang != undefined)
        lang = document.querySelector("html").lang;
    var trans = {
        en:{
            'popup.reset_directory.text':"You're about to reset your entire Product Directory. This action cannot be undone.<br /><br />Are you sure you want continue?",
        },
        fr:{
            'Select a customer to impersonate': 'Sélectionnez un client à impersonifier',
            'Search': 'Rechercher',
            'Back' : 'Retour',
            'Whoops!': 'Oups!',
            'Try changing location or expanding your shopping area.': 'Essayez de changer votre localisation ou d\'élargir votre zone de magasinage.',
            'popup.reset_directory.text': 'Vous êtes sur le point de réinitialiser votre catalogue en entier. Cette action est irréversible.<br /><br />Souhaitez-vous vraiment continuer ?',
            'Open' : 'Ouvert',
            'Closed' : 'Fermé',
            'Keywords' : 'Mots clés',
        }
    }

    function replaceAll($string,$array){
        count = ($string.match(/%s/g) || []).length;
        if(count && count!=$array.length)
            throw new Error("The number of parameters does not match the number of variables.");
        for(var i = 0;i<count;i++){
            $string = $string.replace("%s",$array[i])
        }
        return $string
    }

    this.set = function($lang){
        if(typeof $lang != "string") throw new TypeError("First parameter need to be a string");
        return lang = $lang;
    }
    this.getLang = function(){
        return lang;
    }
    this.add = function($lang,$key,$text){
        if(typeof $lang != "string" || typeof $key != "string") throw new TypeError("All parameter need to be a string");
        if(trans[lang] == undefined)
            trans[$lang] = {};
            trans[$lang][$key] = $text;
    }
    this.trans = function($text,$param,$lang){
        if(typeof $text != "string") throw new TypeError("First parameter need to be a string");
        if(typeof $lang != "string") $lang = $param;
        if(typeof $lang != "string") $lang = lang;
        if(!Array.isArray($param)) $param = [];
        if(trans[$lang]!=undefined && trans[$lang][$text]!=undefined){
            if( typeof trans[$lang][$text] == "string")
                return replaceAll(trans[$lang][$text],$param);
            else if($param.length && !isNaN($param[0]) ){
                if(trans[$lang][$text][$param[0]] != undefined){
                    return replaceAll(trans[$lang][$text][$param[0]],$param);
                }
                else{
                    return replaceAll(trans[$lang][$text][''],$param);
                }
            }
        }
        return replaceAll($text,$param);
    }
};
// PHP like
window.Translator = new TranslatorJS();
// Wordpress like
window.__ = window.Translator.trans;
if (window.jQuery) jQuery.fn._e = function($t,$p,$l) { this.html( window.__($t,$p,$l) ); };
Element.prototype._e = function($t,$p,$l) {this.innerHTML = window.__($t,$p,$l);};
// Twig like
window.trans = window.Translator.trans;
String.prototype.trans = function($p,$l){return window.__(this.toString(),$p,$l);};